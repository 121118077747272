import Vue from "vue"
import Vuex from "vuex"
import tool from './tool'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    tool
  }
})
