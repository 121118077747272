<template>
  <section id="introduction-climate-neutrality" class="section-intro">
    <h1>IntroductionClimateNeutrality</h1>
    {{ content }}
  </section>
</template>

<script>
export default {
  name: 'IntroductionClimateNeutrality',
  props: ['content'],

  data() {
    return {}
  },

  computed: {},

  methods: {}
}
</script>

<style lang="scss">
@import '../../assets/scss/utilities/variables', '../../assets/scss/modules/mixins', '../../assets/scss/modules/pxToRem', '../../assets/scss/vendor/breakpoint';

#introduction-climate-neutrality {
}
</style>
