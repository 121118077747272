const query = `*[_type=="siteStructure" && !(_id in path("drafts.**"))]{
  "main":mpWrappers[] {
    "lang":"de_CH",
    "_id":mainPage->_id,
    "title":mainPage->title,
    "slug":mainPage->slug.current,
    "customType":customType.value,
    "":mainPage->{
      "translations": *[_id in path(^._id + "__i18n_" + __i18n_lang)] {
        _id,
        title,
        "lang":__i18n_lang,
        "slug":slug.current
      }
    }
  }
}`;

export default query;
