<template>
  <nav class="tool-nav">
    <RouterLink v-for="item in main" :key="item._id" :to="`/${locale}/${item.slug}`" v-text="item.title" />
  </nav>
</template>

<script>
import Sanity from '@/sanity'
import MainNavigationQuery from '@/queries/mainNavigation'

export default {
  name: 'NavigationComponent',

  computed: {
    locale () {
      return this.$store.state.tool.locale;
    },

    main () {
      return this.$store.state.tool.navigation.main;
    }
  }
}
</script>

<style lang="scss">
@import
"../../assets/scss/utilities/variables",
"../../assets/scss/modules/mixins",
"../../assets/scss/modules/pxToRem",
"../../assets/scss/vendor/breakpoint";

#tool {
  .tool-nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 26.35%;
    height: 100vh;
    background: $color-blue;

    a {
      display: block;

      &:link, &:active, &:visited {
        color: $color-white;
      }

      &:hover {
        color: $color-white;
      }
    }
  }
}
</style>
