<template>
  <div id="tool" ref="tool">
    <!-- <SplashScreen> -->
    <Navigation />
    <RouterView />
  </div>
</template>

<script>
import Navigation from '@/components/partials/Navigation.vue'

export default {
  name: 'HolcimTool',

  components: {
    Navigation
  },

  data () {
    return {
      data: null,
      timeout: 0,
      scrollCache: 0
    }
  },

  created () {
    // load navigation
    this.$store.dispatch("tool/loadNavigation");

    // add global listeners
    window.addEventListener('scroll', this.scroll, false);
    window.addEventListener('resize', this.resize, false);
  },

  methods: {
    scroll () {
      if (this.timeout) {
        window.cancelAnimationFrame(this.timeout);
      }

      this.timeout = window.requestAnimationFrame(() => {
        let scrollY = window.pageYOffset;

        // store scroll top
        this.$store.commit("tool/setScrollTop", scrollY);

        let scrollDir = scrollY > this.scrollCache ? 'down' : 'up';
        this.scrollCache = scrollY <= 0 ? 0 : scrollY;

        // store scroll direction
        this.$store.commit("tool/setScrollDir", scrollDir);
      });
    },

    resize () {
      let viewportW = window.innerWidth;
      let viewportH = window.innerHeight;

      // store viewport
      this.$store.commit("tool/setViewportWidth", viewportW);
      this.$store.commit("tool/setViewportHeight", viewportH);

      // set real vh to tool root
      this.$refs['tool'].style.setProperty('--vh', `${viewportH}px`);
    }
  },

  mounted () {
    this.scroll();
    this.resize();
  },

  beforeDestroy () {
    // remove global listeners
    window.removeEventListener('scroll', this.scroll, false);
    window.removeEventListener('resize', this.resize, false);
  }
}
</script>

<style lang="scss">
@import "assets/scss/main.scss";

#tool {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
}
</style>
