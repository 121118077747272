<template>
  <section id="introduction-construction-solutions" class="section-intro">
    <h1>IntroductionConstructionSolutions</h1>
    {{ content }}
  </section>
</template>

<script>
export default {
  name: 'IntroductionConstructionSolutions',
  props: ['content'],

  data() {
    return {}
  },

  computed: {},

  methods: {}
}
</script>

<style lang="scss">
@import '../../assets/scss/utilities/variables', '../../assets/scss/modules/mixins', '../../assets/scss/modules/pxToRem', '../../assets/scss/vendor/breakpoint';

#introduction-construction-solutions {
}
</style>
