import Vue from 'vue'
import VueRouter from 'vue-router'
import Introduction from '../views/Introduction.vue'

Vue.use(VueRouter)

const routes = [
  // TODO: Dynamic Routing
  // {
  //   path: '/:lang',
  //   name: 'Introduction',
  //   component: Introduction
  // },
  // {
  //   path: '/:lang/:page',
  //   name: 'Pages',
  //   component: () => import('../views/Pages.vue')
  // },
  {
    path: '/de',
    name: 'Introduction',
    component: Introduction
  },
  {
    path: '/de/klimaneutralitaet',
    name: 'ClimateNeutrality',
    component: () => import('../views/ClimateNeutrality.vue')
  },
  {
    path: '/de/bauloesungen',
    name: 'ConstructionSolutions',
    component: () => import('../views/ConstructionSolutions.vue')
  },
  {
    path: '/de/kreislaufwirtschaft',
    name: 'CircularEconomy',
    component: () => import('../views/CircularEconomy.vue')
  },
  {
    path: '/de/digitalisierung',
    name: 'Digitization',
    component: () => import('../views/Digitization.vue')
  },
  {
    path: '/de/mitmenschen',
    name: 'FellowHumans',
    component: () => import('../views/FellowHumans.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
