<template>
  <section id="introduction-digitization" class="section-intro">
    <h1 class="font-gradient">IntroductionDigitization</h1>
    {{ content }}
  </section>
</template>

<script>
export default {
  name: 'IntroductionDigitization',
  props: ['content'],

  data() {
    return {}
  },

  computed: {},

  methods: {}
}
</script>

<style lang="scss">
@import '../../assets/scss/utilities/variables', '../../assets/scss/modules/mixins', '../../assets/scss/modules/pxToRem', '../../assets/scss/vendor/breakpoint';

#introduction-digitization {
}
</style>
