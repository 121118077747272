<template>
  <section id="introduction-fellow-humans" class="section-intro">
    <h1>IntroductionFellowHumans</h1>
    {{ content }}
  </section>
</template>

<script>
export default {
  name: 'IntroductionFellowHumans',
  props: ['content'],

  data() {
    return {}
  },

  computed: {},

  methods: {}
}
</script>

<style lang="scss">
@import '../../assets/scss/utilities/variables', '../../assets/scss/modules/mixins', '../../assets/scss/modules/pxToRem', '../../assets/scss/vendor/breakpoint';

#introduction-fellow-humans {
}
</style>
