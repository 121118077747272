<template>
  <main class="scroll-by-step-container">
    <template v-for="contentNode in content">
      <Component v-if="contentNode.customType" :is="`Introduction${contentNode.customType}`" :content="contentNode" />
    </template>
  </main>
</template>

<script>
import Sanity from '@/sanity'
import IntroductionQuery from '@/queries/introduction'

// components import
import IntroductionHero from '@/components/content/IntroductionHero.vue'
import IntroductionTextBlock from '@/components/content/IntroductionTextBlock.vue'
import IntroductionClimateNeutrality from '@/components/content/IntroductionClimateNeutrality.vue'
import IntroductionConstructionSolutions from '@/components/content/IntroductionConstructionSolutions.vue'
import IntroductionCircularEconomy from '@/components/content/IntroductionCircularEconomy.vue'
import IntroductionDigitization from '@/components/content/IntroductionDigitization.vue'
import IntroductionFellowHumans from '@/components/content/IntroductionFellowHumans.vue'

export default {
  name: 'PageIntroduction',

  components: {
    IntroductionHero,
    IntroductionTextBlock,
    IntroductionClimateNeutrality,
    IntroductionConstructionSolutions,
    IntroductionCircularEconomy,
    IntroductionDigitization,
    IntroductionFellowHumans
  },

  data() {
    return {
      loading: true,
      content: {}
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      this.error = this.data = null
      this.loading = true

      Sanity.fetch(IntroductionQuery).then(
        (data) => {
          this.loading = false
          this.content = data[0].content || null
        },
        (error) => {
          this.loading = false
          this.error = error
        }
      )
    }
  }
}
</script>
<style scoped>
.scroll-by-step-container {
  overflow-y: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
}
</style>