import Sanity from '@/sanity'
import MainNavigationQuery from '@/queries/mainNavigation'

const state = () => ({
  locale: 'de',
  navigation: {
    main: null,
    language: null
  },
  scroll: {
    y: 0,
    dir: 'down'
  },
  viewport: {
    w: 0,
    h: 0,
    wh: [0,0],
    mobile: true
  }
})

const mutations = {
  setScrollTop (state, scrollTop) {
    state.scroll.y = scrollTop;
  },

  setScrollDir (state, scrollDir) {
    state.scroll.dir = scrollDir;
  },

  setViewportWidth (state, viewportWidth) {
    state.viewport.w = viewportWidth;
    state.viewport.wh = [viewportWidth,state.viewport.h];
    state.viewport.mobile = viewportWidth < 992 ? true : false;
  },

  setViewportHeight (state, viewportHeight) {
    state.viewport.h = viewportHeight;
    state.viewport.wh = [state.viewport.w,viewportHeight];
  },

  setMainNavigation (state, payload) {
    state.navigation.main = payload;
  }
}

const actions = {
  async loadNavigation ({ commit, state }) {
    Sanity.fetch(MainNavigationQuery).then(
      data => {
        const main = data[0].main || [];
        commit('setMainNavigation', main);
      },
      error => {
        console.error('loadNavigation', error);
      }
    )
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
